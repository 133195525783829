/* eslint-disable no-unused-vars */
import Toast from '../components/Common/Toaster/Toaster';
export const ALL_HOUSE_PRODUCTS = 'ALL_HOUSE_PRODUCTS';
export const ALL_MARKET_PRODUCTS = 'ALL_MARKET_PRODUCTS';
export const ADD_NEW_STATE = 'ADD_NEW_STATE';
export const SET_EDIT_PRODUCTS = 'SET_EDIT_PRODUCTS';

const initialState = {
  allHouseProducts: [],
  allMarketProducts: [],
  isDialogOpen: false,
  setEditId: {},
};

const ProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_HOUSE_PRODUCTS:
      return {
        ...state,
        allHouseProducts: action.payload,
      };
    case ALL_MARKET_PRODUCTS:
      return {
        ...state,
        allMarketProducts: action.payload,
      };
    case ADD_NEW_STATE:
      return {
        ...state,
        isDialogOpen: action.payload,
      };

    case SET_EDIT_PRODUCTS:
      return {
        ...state,
        setEditId: action.payload,
      };

    default:
      return state;
  }
};

export default ProductsReducer;

export const fetchAllHouseProducts = (products) => ({
  type: ALL_HOUSE_PRODUCTS,
  payload: products,
});
export const fetchAllMarketProducts = (products) => ({
  type: ALL_MARKET_PRODUCTS,
  payload: products,
});

export const dialogOpen = (boolean) => ({
  type: ADD_NEW_STATE,
  payload: boolean,
});

export const setEdit = (id) => ({
  type: SET_EDIT_PRODUCTS,
  payload: id,
});

export const getAllHouseProducts = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/getall/house/product`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to get products');
      }
      const val = await response.json();
      dispatch(fetchAllHouseProducts(val));
    } catch (error) {}
  };
};

export const getAllMarketProducts = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/getall/market/product`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to get products');
      }
      const val = await response.json();
      dispatch(fetchAllMarketProducts(val));
    } catch (error) {}
  };
};

export const deleteMarket = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/delete/market/product/${id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorMessage = `Failed to delete product: ${response.statusText}`;
        console.error(errorMessage);
        Toast.error('Internal Server Error');
        return;
      }

      Toast.success('Deleted Successfully');

      dispatch(getAllMarketProducts());
    } catch (error) {
      console.error('Error in deleting product:', error);
    }
  };
};

export const createHouseProduct = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/create/house/product`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        Toast.error('Internal Server Error');
        const errorMessage = errorData.message || 'Failed to create';
        throw new Error(errorMessage);
      }
      const val = await response.json();
      Toast.success('Successfully Added');
      dispatch(getAllHouseProducts());
      return val;
    } catch (error) {
      console.error('Error in createHouseProduct:', error);

      throw error;
    }
  };
};

export const deleteHouse = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/delete/house/product/${id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorMessage = `Failed to delete product: ${response.statusText}`;
        console.error(errorMessage);
        Toast.error('Internal Server Error');
        return;
      }

      Toast.success('Deleted Successfully');

      dispatch(getAllHouseProducts());
    } catch (error) {
      console.error('Error in deleting product:', error);
    }
  };
};

export const createMarketProduct = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/create/market/product`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        Toast.error('Internal Server Error');
        const errorMessage = errorData.message || 'Failed to create';
        throw new Error(errorMessage);
      }
      const val = await response.json();
      Toast.success('Successfully Added');
      dispatch(getAllMarketProducts());
      return val;
    } catch (error) {
      console.error('Error in createMarketProduct:', error);

      throw error;
    }
  };
};

export const editHouseProduct = (data, id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/edit/house/product/${id}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        Toast.error('Internal Server Error');
        const errorMessage = errorData.message || 'Failed to edit';
        throw new Error(errorMessage);
      }
      const val = await response.json();
      Toast.success('Successfully Edited');
      dispatch(getAllHouseProducts());
      return val;
    } catch (error) {
      console.error('Error in editHouseProduct:', error);

      throw error;
    }
  };
};

export const editMarketProduct = (data, id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/edit/market/product/${id}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        Toast.error('Internal Server Error');
        const errorMessage = errorData.message || 'Failed to edit';
        throw new Error(errorMessage);
      }
      const val = await response.json();
      Toast.success('Successfully Edited');
      dispatch(getAllMarketProducts());
      return val;
    } catch (error) {
      console.error('Error in editMarketProduct:', error);

      throw error;
    }
  };
};




