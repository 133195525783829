import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/IconButton';
import { SubmitButton } from '../Common/Tools/Buttons';
import Checkbox from '@mui/material/Checkbox';
import {
  createHouseProduct,
  dialogOpen,
  setEdit,
  editHouseProduct,
} from '../../Redux/ProductRedux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FormControlLabel } from '@mui/material';

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 90;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function AddHouseProduct() {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const { allTreatment } = useSelector((state) => state.TreatmentManagement);
  const { allHouseProducts, setEditId } = useSelector(
    (state) => state.ProductManagements
  );
  const [treatmentValues, setTreatmentValues] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [caseDisable, setCaseDisable] = useState(false);
  const [boxDisable, setBoxDisable] = useState(false);
  const [productData, setProductData] = useState({
    productName: '',
    productCode: '',
    description: '',
    manufacturer: '',
    productType: '',
    packSize: '',
    unitsPer: '',
    // productUnits: '',
    productBox: '',
    productCase: '',
    actualPrice: '',
    sellingPrice: '',
    quantityInUnits: '',
    manufacturersRecommendation: '',
    levelOfAdoption: '',
    actualPriceUnit: '',
    addQuantity: '',
    treatmentFor: [],
    showToAll: false,
  });
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    if (allTreatment && allTreatment.length > 0) {
      const values = allTreatment.flatMap((category) =>
        category.categoryValue.map((item) => ({
          _id: item._id,
          value: item.value,
        }))
      );
      setTreatmentValues(values);
    }
  }, [allTreatment]);

  useEffect(() => {
    if (setEditId !== 0 && Object.keys(setEditId).length !== 0) {
      const filteredProduct = allHouseProducts.find(
        (product) => product._id === setEditId
      );
      setEditValue(filteredProduct);
    }
  }, [setEditId]);

  useEffect(() => {
    if (Object.keys(editValue).length !== 0) {
      setProductData((prevData) => ({
        ...prevData,
        productName: editValue.productName || '',
        productCode: editValue.productCode || '',
        description: editValue.description || '',
        manufacturer: editValue.manufacturer || '',
        productType: editValue.productType || '',
        packSize: editValue.packSize || '',
        // productUnits: editValue.productUnits || '',
        productBox: editValue.productBox || '',
        productCase: editValue.productCase || '',
        actualPrice: editValue.actualPrice || '',
        sellingPrice: editValue.sellingPrice || '',
        unitsPer: editValue.unitsPer || '',
        quantityInUnits: editValue.quantityInUnits || '',
        manufacturersRecommendation:
          editValue.manufacturersRecommendation || '',
        levelOfAdoption: parseInt(editValue.levelOfAdoption) || '',
        actualPriceUnit: editValue.actualPriceUnit || '',
        addQuantity: '',
        showToAll: editValue.showToAll || false,
        // const suitableForTypeFormatted = editValue.suitableForType.map((item) =>
        //   parseInt(item.value, 10)
        // );
        // setSuitableForType(suitableForTypeFormatted);
      }));
      const formattedTreatments = editValue.treatmentFor.map((treatment) => ({
        value: treatment.value,
        label: treatment.value,
        _id: treatment._id,
      }));
      if (editValue.productBox) {
        setCaseDisable(true);
      }
      if (editValue.productCase) {
        setBoxDisable(true);
      }
      setSelectedTreatments(formattedTreatments);
      setIsEdit(true);
    }
  }, [editValue]);

  const handleCheckboxChange = (event) => {
    setProductData({
      ...productData,
      showToAll: event.target.checked,
    });
    setSelectedTreatments([]);
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => ({
          value: option.value,
          label: option.label,
          _id: option._id,
        }))
      : [];
    setSelectedTreatments(selectedValues);
  };

  const handleBoxChange = (value) => {
    if (value.trim() !== '') {
      setCaseDisable(true);
      const unitsPerValue = parseInt(productData.unitsPer, 10);
      if (!isNaN(unitsPerValue)) {
        const intValue = parseInt(value, 10) || 0;
        const TotalQuantity = intValue * unitsPerValue;
        setProductData((prevData) => ({
          ...prevData,
          quantityInUnits: TotalQuantity,
          productCase: '',
        }));
      }
    } else {
      setCaseDisable(false);
      setProductData((prevData) => ({
        ...prevData,
        quantityInUnits: '',
      }));
    }
  };

  const handleCaseChange = (value) => {
    if (value.trim() !== '') {
      setBoxDisable(true);
      const unitsPerValue = parseInt(productData.unitsPer, 10);
      if (!isNaN(unitsPerValue)) {
        const intValue = parseInt(value, 10) || 0;
        const TotalQuantity = intValue * unitsPerValue;
        setProductData((prevData) => ({
          ...prevData,
          quantityInUnits: TotalQuantity,
          productBox: '',
        }));
      }
    } else {
      setBoxDisable(false);
      setProductData((prevData) => ({
        ...prevData,
        quantityInUnits: '',
      }));
    }
  };

  const handleUnitPerChange = (value) => {
    const intValue = parseInt(value, 10);

    if (!isNaN(intValue) && value.trim() !== '') {
      let actualPriceValue = parseInt(productData.actualPrice, 10);
      if (!isNaN(actualPriceValue)) {
        const calculatedPrice = actualPriceValue / intValue;
        setProductData((prevData) => ({
          ...prevData,
          actualPriceUnit: calculatedPrice,
        }));
      }
      let productBoxValue = parseInt(productData.productBox, 10);
      if (isNaN(productBoxValue)) {
        productBoxValue = parseInt(productData.productCase, 10);
      }
      if (!isNaN(productBoxValue)) {
        const TotalQuantity = intValue * productBoxValue;
        setProductData((prevData) => ({
          ...prevData,
          quantityInUnits: TotalQuantity,
        }));
      }
    } else {
      setProductData((prevData) => ({
        ...prevData,
        quantityInUnits: '',
      }));
    }
  };

  const handlePriceChange = (value) => {
    const intValue = parseInt(value, 10);
    if (!isNaN(intValue) && value.trim() !== '') {
      let unitsPerValue = parseInt(productData.unitsPer, 10);
      if (!isNaN(unitsPerValue)) {
        const calculatedPrice = intValue / unitsPerValue;
        setProductData((prevData) => ({
          ...prevData,
          actualPriceUnit: calculatedPrice,
        }));
      }
    } else {
      setProductData((prevData) => ({
        ...prevData,
        actualPriceUnit: '',
      }));
    }
  };

  // const handleSuitableClick = (numbers) => {
  //   if (suitableForType.includes(numbers)) {
  //     setSuitableForType(suitableForType.filter((num) => num !== numbers));
  //   } else {
  //     setSuitableForType([...suitableForType, numbers]);
  //   }
  // };

  const handleAdoption = (number) => {
    setProductData((prevData) => ({
      ...prevData,
      levelOfAdoption: prevData.levelOfAdoption === number ? '' : number,
    }));
  };
  const handleSave = () => {
    // const suitableForTypeFormatted = suitableForType.map((value) => ({
    //   value: value.toString(),
    // }));
    const data = {
      ...productData,
      treatmentFor: selectedTreatments,
    };
    if (setEditId !== 0 && Object.keys(setEditId).length !== 0) {
      const id = setEditId;
      dispatch(editHouseProduct(data, id));
      dispatch(setEdit(0));
      dispatch(dialogOpen(false));
    } else {
      dispatch(createHouseProduct(data));
      dispatch(dialogOpen(false));
    }
  };

  return (
    <div className='container-fluid'>
      <div className='col-md-12 p-2 text-center'>
        <h4
          style={{
            backgroundColor: '#002366',
            color: '#fff',
            padding: '10px',
          }}
        >
          Add Products In House
        </h4>
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Product Name'
          variant='standard'
          value={productData.productName}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              productName: e.target.value,
            }))
          }
        />
        <TextField
          className='col-md-5'
          label='Product Code'
          variant='standard'
          value={productData.productCode}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              productCode: e.target.value,
            }))
          }
        />
      </div>

      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Pack Size'
          variant='standard'
          value={productData.packSize}
          inputProps={{ maxLength: 10 }}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              packSize: e.target.value,
            }))
          }
        />
        <TextField
          className='col-md-5'
          label='Product Type'
          variant='standard'
          value={productData.productType}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              productType: e.target.value,
            }))
          }
        />
      </div>

      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Product Box(Total.No Of Box)'
          variant='standard'
          value={productData.productBox}
          onChange={(e) => {
            setProductData((prevData) => ({
              ...prevData,
              productBox: e.target.value,
            }));
            handleBoxChange(e.target.value);
          }}
          disabled={boxDisable}
        />
        <TextField
          className='col-md-5'
          label='Product Case(Total.No Of Case)'
          variant='standard'
          value={productData.productCase}
          onChange={(e) => {
            setProductData((prevData) => ({
              ...prevData,
              productCase: e.target.value,
            }));
            handleCaseChange(e.target.value);
          }}
          disabled={caseDisable}
        />
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='No.Of Units Per Box/Case'
          variant='standard'
          value={productData.unitsPer}
          placeholder='Numbers Only'
          onChange={(e) => {
            setProductData((prevData) => ({
              ...prevData,
              unitsPer: e.target.value,
            }));
            handleUnitPerChange(e.target.value);
          }}
          // InputProps={{
          //   readOnly: isEdit,
          // }}
        />
        <TextField
          className='col-md-5'
          label='Total Quantity in Units'
          variant='standard'
          value={productData.quantityInUnits}
          placeholder='Numbers Only'
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              quantityInUnits: e.target.value,
            }))
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Actual Price Per Box/Case'
          variant='standard'
          value={productData.actualPrice}
          placeholder='Singapore Dollar'
          onChange={(e) => {
            setProductData((prevData) => ({
              ...prevData,
              actualPrice: e.target.value,
            }));
            handlePriceChange(e.target.value);
          }}
        />

        <TextField
          className='col-md-5'
          label='Actual Price Per Unit'
          variant='standard'
          // disabled={!isEdit}
          value={productData.actualPriceUnit}
          placeholder='Singapore Dollar'
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              actualPriceUnit: e.target.value,
            }))
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </div>

      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='selling Price Per Units'
          variant='standard'
          // disabled={!isEdit}
          value={productData.sellingPrice}
          placeholder='Numbers Only'
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              sellingPrice: e.target.value,
            }))
          }
        />
        {/* <TextField
          className='col-md-5'
          label='Products Units'
          variant='standard'
          value={productData.productUnits}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              productUnits: e.target.value,
            }))
          }
        /> */}
        <TextField
          className='col-md-5'
          label='+ Add More Box/Case'
          variant='standard'
          disabled={!isEdit}
          // value={productData.productUnits}
          onChange={(e) => {
            setProductData((prevData) => ({
              ...prevData,
              addQuantity: e.target.value,
            }));
            // handleQuantityChange(e.target.value);
          }}
        />
      </div>

      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Manufacturer'
          variant='standard'
          value={productData.manufacturer}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              manufacturer: e.target.value,
            }))
          }
        />
        <TextField
          className='col-md-5'
          label='Description'
          variant='standard'
          value={productData.description}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              description: e.target.value,
            }))
          }
        />
      </div>

      <div className='row d-flex justify-content-around mt-4'>
        <div className='col-md-4'>
          <p className='row d-flex justify-content-start fs-6'>
            Level Of Adoption Scale (1-5)*
          </p>
          {[1, 2, 3, 4, 5].map((number) => (
            <Button
              className='row'
              key={number}
              variant='outlined'
              onClick={() => handleAdoption(number)}
              sx={{
                fontSize: '15px',
                marginRight: '10px',
                marginBottom: '10px',
                backgroundColor:
                  productData.levelOfAdoption === number
                    ? '#f0f0f0'
                    : 'transparent',
                color: productData.levelOfAdoption === number ? '#000' : '',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              {number}
            </Button>
          ))}
        </div>
        {/* <div className='col-md-5'> */}

        {/* </div> */}
      </div>

      <div className='row d-flex justify-content-around mt-3'>
        <TextField
          className='col-md-11'
          variant='outlined'
          multiline
          rows={4}
          // fullWidth
          label='Manufacturers Recommendation In Brief'
          value={productData.manufacturersRecommendation}
          onChange={(e) =>
            setProductData((prevData) => ({
              ...prevData,
              manufacturersRecommendation: e.target.value,
            }))
          }
        />
      </div>

      <div className='row d-flex justify-content-around mt-2'>
        <FormControl className='col-md-11' sx={{ m: 1 }}>
          <div className='col-12 mb-3'>
            <label>Treatments</label>
            <Select
              id='treatmentSuggestions'
              options={treatmentValues.map((treatment) => ({
                value: treatment.value,
                label: treatment.value,
                _id: treatment._id,
              }))}
              value={selectedTreatments}
              placeholder='Select options'
              isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isMulti
              components={animatedComponents}
              onChange={handleChange}
              menuPlacement={'top'}
              isDisabled={productData.showToAll}
            />
          </div>
        </FormControl>
      </div>
      <div className='row d-flex justify-content-start mt-2'>
        <FormControlLabel
          className='col-md-11'
          sx={{ m: 1 }}
          control={
            <Checkbox
              checked={productData.showToAll}
              onChange={handleCheckboxChange}
            />
          }
          label='Show To All Treatments'
        />
      </div>
      <div className='row d-flex mt-4 justify-content-center'>
        <SubmitButton onClick={handleSave}>Save</SubmitButton>
      </div>
    </div>
  );
}
