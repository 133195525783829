import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Image } from "react-bootstrap";
import LoginScreenDoctor from '../../assets/images/LoginScreenDoctor.jpg';
import kromhLogo from '../../assets/images/KromhLogo.jpg';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoginButton } from '../Common/Tools/Buttons';
import { Link, useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { useDispatch } from 'react-redux';
import { LoginUser } from '../../Redux/LoginRedux';
import { FetchAdmin } from '../../Redux/StaffRedux';
import Swal from 'sweetalert2';

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = () => {
    if (userName !== '' && password !== '') {
      login();
    } else {
      alert('please fill username and password');
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    validate();
  };
  const login = () => {
    const data = { username: userName, password: password };
    dispatch(LoginUser(data)).then((response) => {
      if (response.message === 'User not found') {
        Swal.fire({
          icon: 'error',
          text: 'User not found',
          customClass: {
            popup: 'small-swal',
          },
        });
      } else if (response.message === 'Invalid password') {
        Swal.fire({
          icon: 'error',
          text: 'Invalid  Password',
          customClass: {
            popup: 'small-swal',
          },
        });
      } else if (response.user.userRole === 'admin') {
        const emailId = response.user.emailId;
        dispatch(FetchAdmin(emailId));
        sessionStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('authenticated', true);
        navigate('./patients');
      } else if (response.user.userRole !== 'admin') {
        Swal.fire({
          icon: 'error',
          text: 'User not found',
          customClass: {
            popup: 'small-swal',
          },
        });
      }
    });
  };

  return (
    <div className='container-fluid'>
      <div className='row d-flex justify-content-center  align-items-center p-2'>
        <div className='col-md-12 d-inline-flex col-lg-5 col-sm-12 login-bg p-0'>
          <Image
            src={LoginScreenDoctor}
            style={{ width: '90%', height: '100%', objectFit: 'cover' }}
            fluid
          />
        </div>

        <div className='col-md-12 col-lg-6 col-sm-12 p-0'>
          <div
            className='card shadow-lg border-0 d-flex align-items-stretch p-2'
            style={{
              borderRadius: '2rem',
            }}
          >
            <div className='row'>
              <div className='col-12'>
                <div className='col-12 mb-4'>
                  <div className='d-flex justify-content-center'>
                    <Image
                      style={{ width: '40%', height: '40%' }}
                      src={kromhLogo}
                    />
                  </div>

                  <div
                    style={{
                      borderImage:
                        'linear-gradient(to right, #0acffe 0%, #495aff 100%) 1',
                      borderBottom: '2px solid',
                      borderWidth: '0 0 2px 0',
                      width: '30%',
                      margin: 'auto',
                    }}
                  ></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='row d-flex justify-content-center p-2 g-5'>
                    <div className='col-12 col-lg-8'>
                      <TextField
                        fullWidth
                        label='Username'
                        variant='outlined'
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    <div className='col-12 col-lg-8'>
                      <TextField
                        fullWidth
                        onChange={(e) => setPassword(e.target.value)}
                        label='Password'
                        type={showPassword ? 'text' : 'password'}
                        variant='outlined'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center align-item-center pb-3'>
                    <div className='col-10 col-lg-5 g-3'>
                      {' '}
                      <LoginButton type='submit'> Login</LoginButton>
                    </div>
                    {/* <div className="col-10 col-lg-12 g-3 text-center">
                      {' '}
                      <Link
                        to="/Forgot-Password"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        Forgot password?
                      </Link>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}