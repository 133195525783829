import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { fetchPdfUrl } from '../../Redux/PatientRedux';
import CircularProgress from '@mui/material/CircularProgress';

function PatientTable() {
  const { allPatients } = useSelector((state) => state.PatientDetails);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDetails = (row) => {
    setIsLoading(true);
    dispatch(fetchPdfUrl({ id: row.patientId, visit: row.visit })).then((result) => {
      if (result) {
        console.log("result", result);
        setIsLoading(false)
      }
    }).catch((error) => {
      setIsLoading(false)
      console.error("Error fetching PDF URL:", error);
    });
  };

  // Define the columns for the DataGrid without the 'id' column
  const columns = [
    { field: 'patientId', headerName: 'Patient ID', flex: 1 },
    { field: 'patientName', headerName: 'Name', flex: 1 },
    { field: 'visit', headerName: 'Visit', flex: 1 },
    { field: 'age', headerName: 'Age', flex: 1 },
    { field: 'sex', headerName: 'Sex', flex: 1 },
    // { field: 'institution', headerName: 'Institution',  flex: 1, },
    // { field: 'woundLocation', headerName: 'Wound Location',  flex: 1, },
    // { field: 'woundType', headerName: 'Wound Type',  flex: 1, },
    // { field: 'infectionObservation', headerName: 'Infection Observation',  flex: 1, },
    { field: 'createdAtDate', headerName: 'Date', flex: 1 },
    // { field: 'createdAtTime', headerName: 'Time',  flex: 1, },
    { field: 'consultedBy', headerName: 'Consulted By', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div className='row d-flex justify-content-center'>
          <div className='col-md-12'>
            <Button onClick={() => handleDetails(params.row)}>
              View Details
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const rows = allPatients.map((patient, index) => {
    const createdAt = new Date(patient.createdAt);
    const createdAtDate = createdAt.toLocaleDateString('en-GB');
    // const createdAtTime = createdAt.toLocaleTimeString();

    return {
      id: patient._id, // Using patientId as unique identifier
      patientId: patient.patientInformation.patientId,
      patientName: patient.patientInformation.patientName,
      visit: patient.patientInformation.visit,
      age: patient.patientInformation.age,
      sex: patient.patientInformation.sex,
      // institution: patient.patientInformation.institution,
      // woundLocation: patient.locationDimension.woundLocation,
      // woundType: patient.woundType.woundType,
      // infectionObservation: patient.woundAssesment.infection.infectionObservation,
      createdAtDate,
      // createdAtTime,
      consultedBy: patient.consultedBy,
    };
  });
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: 'flex',
          borderBottom: '1px solid #E0E0E0',
          padding: '10px',
        }}>
        <GridToolbarExport />
        <div style={{ flex: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="container">
      <Box sx={{ position: 'relative' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[10, 20, 30]}
          pagination
          sx={{
            filter: isLoading ? 'blur(4px)' : 'none',
          }}
        />
      </Box>
    </div>
  );
}

export default PatientTable;
